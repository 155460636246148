import React from 'react';
import '../App';
import { Link } from "react-router-dom";

import logoNav from '../img/navLogo.svg';

import discord from '../img/socialmedia/darkDiscord.svg';
import twitter from '../img/socialmedia/darkTwitter.svg';
import instagram from '../img/socialmedia/darkInstagram.svg';


export default function Nav() {
  return (
  <nav>
    <div id="navCont">
      <div id="navName">
        <img id="logoNav"  src={logoNav}/>
        <Link to="/"><div id="name">Cactus Kids Health Club</div></Link>
      </div>
      <div id="navLink">
        <div id="socialMedia">
          <a href="https://twitter.com/cactuskidshc" target="_blank" rel="noreferrer"><img id="socialImg" src={twitter}/></a>
          <a href="https://www.instagram.com/cactuskidshc/" target="_blank" rel="noreferrer"><img id="socialImg" src={instagram}/></a>
          <a href="https://discord.gg/VNAZenZadj" target="_blank" rel="noreferrer"><img id="socialImg" src={discord}/></a>
          </div>
          <div id="h5"><a href="https://opensea.io/collection/cactuskidshc" target="_blank" rel="noreferrer" >opensea</a></div>
          <div><Link to="/mint"><button id="h4" class="joinBtn">Mint Now</button></Link> </div>
      </div>
    </div>           
  </nav>
  )
}

//scroll bar + nav
  window.addEventListener('scroll', function () {
    let header = document.getElementById('navCont');
    let windowPosition = window.scrollY > 0;
    header.classList.toggle('scrolling-active', windowPosition);
  })
