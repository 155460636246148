import React, { useEffect, useState } from 'react'

import Nav from '../components/Nav';
import Footer from '../components/Footer';

import mintNFT from '../img/mintNFTimg.png';

export default function Mint() {
    const [state, setState] = useState({
        isLoading: true // default
    });

    useEffect(() => {
        <script defer="defer" src=""></script>

        const script = document.createElement('script');

        script.src = `https://cdn.jsdelivr.net/gh/ambition-so/embed-prod-build@main/bundle.js`;
        script.defer = `defer`;
        document.body.appendChild(script);

        setTimeout(() => setState(prevState => ({ ...prevState, isLoading: false })), 1000);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <body>
            <Nav />
            <section id="mintSection">
                <div id="h1">Join the community!</div>
                {state.isLoading && '.... loading'}
                <div style={{ display: state.isLoading && 'none' || 'block' }} dangerouslySetInnerHTML={{ __html: `<ambition-button chainid="1" contractaddress="0x762a2f6c6F9fE9A46a5B504A0d120A3666bbFA7d" />` }} />
            </section>
            <img id="mintNFT" src={mintNFT} />
            <Footer />

        </body>
    )
}
