import React from 'react';
import '../App';

import discord from '../img/socialmedia/lightDiscord.svg';
import twitter from '../img/socialmedia/lightTwitter.svg';
import instagram from '../img/socialmedia/lightInstagram.svg';


import footerJA from '../img/JACentralOntario.svg';



export default function Footer() {
  return (
    <section id="footer">
    <div id="footerCont">
      <div id="name">Cactus Kids Health Club</div>
      <div id="socialMedia">
        <a href="https://twitter.com/cactuskidshc" target="_blank" rel="noreferrer"><img id="socialImg" src={twitter}/></a>
        <a href="https://www.instagram.com/cactuskidshc/" target="_blank" rel="noreferrer"><img id="socialImg" src={instagram}/></a>
        <a href="https://discord.gg/VNAZenZadj" target="_blank" rel="noreferrer"><img id="socialImg" src={discord}/></a>
        <a href="mailto: heyo@cactuskidshc.com" target="_blank" rel="noreferrer"><div id='text'>heyo@cactuskidshc.com</div></a>
      </div>
      <img src={footerJA} alt="JA Central Ontario"/>
      <div id="text">Made with ❤️ by the <b id="nameText">Cactus Kids</b> team</div>
    </div>
  </section>
  )
}
