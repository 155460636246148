import React from 'react'

import '../App.css';
import Nav from '../components/Nav';


import sam from '../img/team/sam.png';
import ryan from '../img/team/ryan.png';
import kelly from '../img/team/kelly.png';
import heidi from '../img/team/heidi.png';
import will from '../img/team/will.png';
import isabelle from '../img/team/isabelle.png';
import ekam from '../img/team/ekam.png';
import mila from '../img/team/mila.png';


export default function generalTeam() {
  return(
    <body>
        <Nav />
        <section id="team">
        <div id="h2"> Meet Our Team</div>
              <div class="teamCont">
                <div class="teamBox">
                  <img src={sam} alt=""/>
                  <div class="teamText">
                    <div id="h4">SAMUEL LO</div>
                    <div id="text">The Visionary</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={ryan} alt=""/>
                  <div class="teamText">
                    <div id="h4">Ryan Shum</div>
                    <div id="text">The Architect</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={kelly} alt=""/>
                  <div class="teamText">
                    <div id="h4">kelly guan</div>
                    <div id="text">The Engineer</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={heidi} alt=""/>
                  <div class="teamText">
                    <div id="h4">heidi song</div>
                    <div id="text">The Artist</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={will} alt=""/>
                  <div class="teamText">
                    <div id="h4">will lin</div>
                    <div id="text">The Mediator</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={ekam} alt=""/>
                  <div class="teamText">
                    <div id="h4">ekam sidhu</div>
                    <div id="text">Master of Coin</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={mila} alt=""/>
                  <div class="teamText">
                    <div id="h4">mila jokic</div>
                    <div id="text">The Negotiator</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={isabelle} alt=""/>
                  <div class="teamText">
                    <div id="h4">isabelle gan</div>
                    <div id="text">The Persuader</div>
                  </div>
                </div>
              </div>

              <div class="teamWrapper">
                <hr id="line1"></hr>
                <hr id="line2"></hr>
                <div id="text">
                  Aryan.Vipra.Selena.Arman.Kevin.Kelly.Nishta.Aaron.Tea.Andy.Aarna.Alice.Alyssa.Aryan.Vipra.Selena.Arman.Kevin.Kelly.Nishta.Aaron.Tea.Andy.Aarna.Alice.Alyssa.Aryan.Vipra.Selena.Arman.Kevin.Kelly.Nishta.Aaron.Tea.Andy.Aarna.Alice.Alyssa.Aryan.Vipra.Selena.Arman.Kevin.Kelly.Nishta.Aaron.Tea.Andy.Aarna.Alice.Alyssa.
                </div>
                <hr id="line3"></hr>
                <hr id="line4"></hr>
              </div>
        </section>

    </body> 
  )
}