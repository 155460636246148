import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";

import './App.css';

/* COMPONENT IMPORT */
import Nav from './components/Nav';
import Footer from './components/Footer';

import loader from './img/loader.png';
import landingGif from "./img/landingGif.gif"

/* ABOUT IMG IMPORT */
import us from './img/whoarewe.png';
import reward from './img/reward.png';

/* ROADMAP IMG IMPORT */
import roadmap from './img/roadmap.png';
import respRoadmap from './img/responsiveRoadmap.png';

/* TEAM IMG IMPORT */
import sam from './img/team/sam.png';
import ryan from './img/team/ryan.png';
import kelly from './img/team/kelly.png';
import heidi from './img/team/heidi.png';
import will from './img/team/will.png';
import isabelle from './img/team/isabelle.png';
import ekam from './img/team/ekam.png';
import mila from './img/team/mila.png';


function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (  
    <div class="loader">
      {
        loading ? // Loading IMG for 2s 
        <div id="loaderSection">
          <img id="loaderImg"  src={loader} loading={loading}/>
        </div>
        : 
        <body>
        <Nav />
        <section id="landing">
          <div id="landingCont">
            <div class="landingText">
              <div id="h1">
                A global NFT community inspiring healthier lifestyles to <div id="hashTag">#LiveBetterLiveLonger</div>
              </div>
              <div id="landingBtns">
              <Link to="/mint"><button id="h4" class="joinBtn">Mint Now</button></Link>
                <a href="https://discord.gg/VNAZenZadj" target="_blank" rel="noreferrer"><button id="h4" class="discordBtn">Discord</button></a>
              </div>
              </div>
              <div class="landingBox">
              <img src={landingGif} alt="wait until the page loads" />            
              </div>
              </div>
        </section> 

        <section id="about">
              <div class="aboutCont">
                <div class="aboutText">
                  <div id="h2">Who are the Cactus Kids?</div>
                  <div id="text">
                    <p><b id="nameText">Cactus Kids Health Club</b> is a collection of 1,111 Cactus Kids learning to build healthy habits and improve their fitness, nutrition, mindset and sleep schedule. </p>
                      Enter an empowering and health-savvy community, engage with wellness experts, and gain discounts to top lifestyle brands!
                  </div>
                </div>
                <img src={us} alt=""/>
              </div>

              <div class="aboutCont" id="aboutCont2">
                <img src={reward} alt=""/>
                  <div class="aboutText">
                    <div id="h2">Get Rewarded For Exercising</div>
                    <div id="text">
                      Compete against fellow Cactus Kids in our Fitness Challenge for the chance to win medals, participate in weekly raffles, and claim the top prize. No experience required, just show up and stay active!
                    </div>
                  </div>
              </div>
        </section> 

        <section id="roadmap">
              <div class="roadmapSection">
                <img id="roadmapImg" src={roadmap} alt=""/> 
                <img id="respRoadmap" src={respRoadmap} alt=""/> 
                <ul id="roadmapCont">    
                  <li class="roadmapBox"> 
                      <div id="h1">10%</div>
                      <div id="line"></div>
                    <div id="text">
                      <p>Fitness Challenge Prizes Unlocked</p>
                      <p>Exclusive deals with top wellness brands</p>
                      <p>Weekly community events and speakers </p>
                    </div>
                  </li>

                  <li class="roadmapBox"> 
                      <div id="h1">25%</div>
                      <div id="line"></div>
                    <div id="text">
                      <p>🚴 Fitness Challenge Top Prize: Peloton Bikes </p>
                      <p>3 Cactus Kids airdropped to community members</p>
                    </div>
                  </li>

                  <li class="roadmapBox"> 
                      <div id="h1">50%</div>
                      <div id="line"></div>
                    <div id="text">
                      <p>Fitness Challenge Top Prize:🚴 ? 🥗 ?</p>
                      <p>20% of earnings donated to community initiatives of your choice</p>
                    </div>
                  </li>
                  
                  <li class="roadmapBox"> 
                      <div id="h1">75%</div>
                      <div id="line"></div>
                    <div id="text">
                      <p>Fitness Challenge Top Prize:🚗 ?</p>
                      <p>5 Cactus Kids airdropped to community members</p>
                    </div>
                  </li>

                  <li class="roadmapBox"> 
                      <div id="h1">100%</div>
                      <div id="line"></div>
                    <div id="text">
                      <p>Exclusive merch release & giveaway</p>
                      <p>Expand collaboration with health brands & experts</p>
                    </div>
                  </li>

                  </ul>
                </div>
        </section>

        <section id="team">
             <Link to="/generalTeam"><div id="h2"> Meet Our Team</div></Link>
              <div class="teamCont">
                <div class="teamBox">
                  <img src={sam} alt=""/>
                  <div class="teamText">
                    <div id="h4">SAMUEL LO</div>
                    <div id="text">The Visionary</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={ryan} alt=""/>
                  <div class="teamText">
                    <div id="h4">Ryan Shum</div>
                    <div id="text">The Architect</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={kelly} alt=""/>
                  <div class="teamText">
                    <div id="h4">kelly guan</div>
                    <div id="text">The Engineer</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={heidi} alt=""/>
                  <div class="teamText">
                    <div id="h4">heidi song</div>
                    <div id="text">The Artist</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={will} alt=""/>
                  <div class="teamText">
                    <div id="h4">will lin</div>
                    <div id="text">The Mediator</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={ekam} alt=""/>
                  <div class="teamText">
                    <div id="h4">ekam sidhu</div>
                    <div id="text">Master of Coin</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={mila} alt=""/>
                  <div class="teamText">
                    <div id="h4">mila jokic</div>
                    <div id="text">The Negotiator</div>
                  </div>
                </div>
                <div class="teamBox">
                  <img src={isabelle} alt=""/>
                  <div class="teamText">
                    <div id="h4">isabelle gan</div>
                    <div id="text">The Persuader</div>
                  </div>
                </div>
              </div>
        </section>

        <section id="faq">
              <div id="h2"> FAQ</div>
              <div class="faqCont">
                  <div class="faqBox" id="question1">
                    <a href="#question1" id="h4">
                      What is Cactus Kids Health Club?
                      <ion-icon id="icon-down"  name="chevron-down-outline"></ion-icon>                      
                      <ion-icon id="icon-up" name="chevron-up-outline"></ion-icon>
                    </a>
                    <div class="answer"><p id="text">
                    Cactus Kids Health Club is a NFT collection of cacti 🌵 minted on the Ethereum blockchain. The collection will include species of cacti paired with different accessories while engaging in diverse healthy living activities. Every Cactus Kid will be empowered, energetic, and united in our mission.
                    </p></div>                  
                  </div>

                  <div class="faqBox" id="question2">
                    <a href="#question2" id="h4">
                      Why a collection size of 1,111?
                      <ion-icon id="icon-down"  name="chevron-down-outline"></ion-icon>                      
                      <ion-icon id="icon-up" name="chevron-up-outline"></ion-icon>
                    </a>
                    <div class="answer"><p id="text">
                    At Cactus Kids Health Club, we care for your happiness, health and prosperity. We want to manifest positive thoughts/actions for you. Angel Number 1,111 🌵 symbolizes a great leap forward on your spiritual journey and is associated with growth in creativity, motivation, innovation.
                    </p></div>                  
                  </div>

                  <div class="faqBox" id="question3">
                    <a href="#question3" id="h4">
                    What is Cactus Kids Health Club's mission? 
                      <ion-icon id="icon-down"  name="chevron-down-outline"></ion-icon>                      
                      <ion-icon id="icon-up" name="chevron-up-outline"></ion-icon>
                    </a>
                    <div class="answer"><p id="text">
                    To empower a new generation of health-driven individuals to live better, longer.
                    </p></div>                  
                  </div>

                  <div class="faqBox" id="question5">
                    <a href="#question5" id="h4">
                    What are the benefits?
                      <ion-icon id="icon-down" name="chevron-down-outline"></ion-icon>                      
                      <ion-icon id="icon-up" name="chevron-up-outline"></ion-icon>
                    </a>
                    <div class="answer"><p id="text">
                    As a member of Cactus Kids Health Club, you will receive access to exclusive brand discounts for health-related products, engage with leading minds in the health & wellness space at member-only events, and bond with an uplifting community of fellow Cactus Kids.
                    </p></div>    
                  </div>
                        
                  <div class="faqBox" id="question6">
                    <a href="#question6" id="h4">
                      How much will it cost
                      <ion-icon id="icon-down" name="chevron-down-outline"></ion-icon>                      
                      <ion-icon id="icon-up" name="chevron-up-outline"></ion-icon>
                    </a>
                    <div class="answer"><p id="text">Current Price: 0.03 ETH </p></div>    
                  </div>

                  <div class="faqBox" id="question7">
                    <a href="#question7" id="h4">
                    Where can i view my nft?
                      <ion-icon id="icon-down" name="chevron-down-outline"></ion-icon>                      
                      <ion-icon id="icon-up" name="chevron-up-outline"></ion-icon>
                    </a>
                    <div class="answer"><p id="text">
                      You will be able to view your Cactus Kid's in your personal cryptocurrency wallets whether that may be Coinbase or Metamask.
                      </p></div>    
                  </div>

                  <div class="faqBox" id="question8">
                    <a href="#question8" id="h4">
                    when will the collection be released?
                      <ion-icon id="icon-down" name="chevron-down-outline"></ion-icon>                      
                      <ion-icon id="icon-up" name="chevron-up-outline"></ion-icon>
                    </a>
                    <div class="answer"><p id="text">
                    Our current date for minting the Cactus Kids is April 09, 2022 @12:00:00.
                    </p></div>    
                  </div>

                  <div class="faqBox" id="question9">
                    <a href="#question9" id="h4">
                    i'm new to nfts, how do i get started?
                      <ion-icon id="icon-down" name="chevron-down-outline"></ion-icon>                      
                      <ion-icon id="icon-up" name="chevron-up-outline"></ion-icon>
                    </a>
                    <div class="answer"><p id="text">
                    NFTs are not that complicated. NFTs are non-fungible tokens that you can buy using a cryptocurrency that goes to your wallet and usually comes in the form of art like the Cactus Kids but can be other things as well. 
                    </p></div>    
                  </div>

                  <div class="faqBox" id="question10">
                    <a href="#question10" id="h4">
                    Do I own the Cactus Kid after I mint? 

                      <ion-icon id="icon-down" name="chevron-down-outline"></ion-icon>                      
                      <ion-icon id="icon-up" name="chevron-up-outline"></ion-icon>
                    </a>
                    <div class="answer"><p id="text">
                      Yes, after you buy the Cactus Kid it will appear in your NFT wallet and you should own it.
                    </p></div>    
                  </div>
              </div>
        </section>

       <Footer />
      </body>
      }
    </div>
  );
}

export default App;
