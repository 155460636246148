import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import App from './App';
import Mint from './pages/mint';
import GeneralTeam from './pages/generalTeam';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<App/>} />
        <Route path="mint" element={<Mint/>} />
        <Route path="generalTeam" element={<GeneralTeam/>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

